import { Environments, getCurrentEnvironment } from './environments'
import { isValidBrand, BRANDS } from './brands'

interface NavLink {
  url: string
  label: string
}

export interface SurveyTheme {
  name: BRANDS
  pageTitle: string
  links: NavLink[]
  favicon: string
  hosts: {
    [key in Environments]: string[]
  }
  oneTrustDataDomainScript: string
}

const themes: Record<BRANDS, SurveyTheme> = {
  [BRANDS.SAINSBURYS]: {
    name: BRANDS.SAINSBURYS,
    pageTitle: 'Sainsbury’s Feedback',
    links: [],
    favicon: '/images/sainsburys.favicon.ico',
    hosts: {
      [Environments.local]: ['http://localhost:3000'],
      [Environments.dev]: ['https://feedback.sainsburys.dev.csat.cmdtests.com'],
      [Environments.prod]: ['https://feedback.sainsburys.co.uk'],
    },
    oneTrustDataDomainScript: '184ebe95-785b-448f-b0ad-cf0111c54adf',
  },

  [BRANDS.ARGOS]: {
    name: BRANDS.ARGOS,
    pageTitle: 'Argos Feedback',
    links: [],
    favicon: '/images/argos.favicon.ico',
    hosts: {
      [Environments.local]: ['http://localhost:3000'],
      [Environments.dev]: ['https://feedback.argos.dev.csat.cmdtests.com'],
      [Environments.prod]: ['https://feedback.argos.co.uk'],
    },
    oneTrustDataDomainScript: '194b2214-abb6-4646-abdf-bcbf9390d131',
  },

  [BRANDS.TU]: {
    name: BRANDS.TU,
    pageTitle: 'Tu Feedback',
    links: [],
    favicon: '/images/tu.favicon.ico',
    hosts: {
      [Environments.local]: ['http://localhost:3000'],
      [Environments.dev]: [
        'https://feedback.tu.sainsburys.dev.csat.cmdtests.com',
        'https://feedback.tu.argos.dev.csat.cmdtests.com',
      ],
      [Environments.prod]: [
        'https://feedback.tu.sainsburys.co.uk',
        'https://feedback.tu.argos.co.uk',
      ],
    },
    oneTrustDataDomainScript: '2d961281-3e85-489b-a7c8-bcada396bb49',
  },

  [BRANDS.HABITAT]: {
    name: BRANDS.HABITAT,
    pageTitle: 'Habitat Feedback',
    links: [],
    favicon: '/images/habitat.favicon.ico',
    hosts: {
      [Environments.local]: ['http://localhost:3000'],
      [Environments.dev]: ['https://feedback.habitat.dev.csat.cmdtests.com'],
      [Environments.prod]: ['https://feedback.habitat.co.uk'],
    },
    oneTrustDataDomainScript: '018e1372-4a34-706f-a0d8-703ff93d07a2',
  },

  [BRANDS.NECTAR]: {
    name: BRANDS.NECTAR,
    pageTitle: 'Nectar Feedback',
    links: [],
    favicon: '/images/nectar.favicon.ico',
    hosts: {
      [Environments.local]: ['http://localhost:3000'],
      [Environments.dev]: ['https://feedback.nectar.dev.csat.cmdtests.com'],
      [Environments.prod]: ['https://feedback.nectar.com'],
    },
    oneTrustDataDomainScript: '018e137c-7dbf-7894-aae1-d09b7a80f71e',
  },
}

const getFirstThemeHost = (brand: BRANDS): string | undefined => {
  const env = getCurrentEnvironment()

  return themes[brand].hosts[env][0]
}

const getBrandByThemeHost = (host: string): BRANDS => {
  const detectedBrand = Object.entries(themes).find(([_, theme]) => {
    return theme.hosts[getCurrentEnvironment()].includes(host)
  })

  if (detectedBrand && isValidBrand(detectedBrand[0])) {
    return detectedBrand[0]
  }

  return BRANDS.SAINSBURYS
}

export { themes, getFirstThemeHost, getBrandByThemeHost }
