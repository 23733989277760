enum Environments {
  local = 'local',
  dev = 'development',
  prod = 'production',
}

const isValidEnvironment = (
  environment: unknown
): environment is Environments => {
  return (
    environment !== undefined &&
    typeof environment === 'string' &&
    Object.values(Environments).includes(environment as Environments)
  )
}

const getCurrentEnvironment = (): Environments => {
  const envStage = process.env.MODE
  if (isValidEnvironment(envStage)) {
    return envStage
  }
  return Environments.local
}

export { Environments, isValidEnvironment, getCurrentEnvironment }
